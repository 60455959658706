// const script = document.createElement('script');
// script.src = "https://ai.ocelotbot.com/embed/standard/40311583-8d8d-4681-a381-d063d9ba4b76?ha=right&amp;bs=simple";
// script.async = true;
// const current = window.location.pathname;
// if (current != '/dream-forward' && current != '/youbelong' && current != '/' && current != '/dream-forward-os') {
//     document.body.appendChild(script);
// }

// let isOnMobileView = window.innerWidth < 1200;

// function waitForElement(selector) {
//     return new Promise(resolve => {
//         if (document.querySelector(selector)) {
//             return resolve(document.querySelector(selector));
//         }

//         const observer = new MutationObserver(mutations => {
//             if (document.querySelector(selector)) {
//                 resolve(document.querySelector(selector));
//                 observer.disconnect();
//             }
//         });

//         observer.observe(document.body, {
//             childList: true,
//             subtree: true
//         });
//     });
// }

// waitForElement('.fatv18').then(() => {
//     if (isOnMobileView) {
//         (function annonymousFunction() {
//             let chatContainerTextMobile = document.querySelector(".MaxAI");
//             let chatContainer = chatContainerTextMobile.firstChild.firstChild;
//             let chatCloseButtonMobile = document.getElementsByClassName("fatv55 fatv147 fatv129 fatv150")[0];
//             let chatButton = document.getElementsByClassName("fatv55 fatv45 fatv20 fatv21 fatv49 fatv48")[0];
//             if (chatContainer) {
//                 chatContainer.style.display = "flex";
//                 chatContainer.style.bottom = "180px";
//                 chatContainer.style.right = "10px";
//                 if (chatButton) {
//                     chatButton.addEventListener("click", function () {
//                         console.log("button clicked");
//                         let changeInput = document.getElementById('ask-a-question-input')
//                         changeInput.focus(function () {
//                             document.activeElement.blur();
//                         })
//                     }, false)
//                 }
//                 if (chatCloseButtonMobile) {
//                     chatCloseButtonMobile.addEventListener("click", function () {
//                         location.reload();
//                     }, false)
//                 }
//             }
//         })();
//     }

//     if (!isOnMobileView) {
//         (function annonymousFunction2() {
//             // if (current != '/') {
//             let chatContainerText = document.getElementsByClassName("fatv67 fatv23 fatv25 fatv29 fatv71 fatv89")[0];
//             let chatCloseButton = document.getElementsByClassName("fatv55 fatv147 fatv129 fatv150")[0];
//             if (chatContainerText) {
//                 chatContainerText.innerHTML = "";
//                 if (chatCloseButton) {
//                     chatCloseButton.addEventListener("click", function () {
//                         location.reload();
//                     }, false)
//                 }
//             }
//             // }
//         })();
//     }
// });

(function () {
  const alertMessage = document.querySelectorAll(".alert[data-expires]");
  if (alertMessage) {
    [].slice
      .apply(alertMessage)
      .map(function (a) {
        return {
          date: Date.parse(a.dataset.expires),
          element: a,
        };
      })
      .filter(function (i) {
        return i.date < Date.now();
      })
      .forEach(function (i) {
        i.element.style.display = "none";
      });
  }
})();
